import React, { useContext, useState, useEffect } from "react";
import { LicenseConfigureContext, spl_format } from "..";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import LicenseConfigureServices from "../../../../services/API/LicenseConfiguration";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import AddCategories from "../Modals/AddCategories";
import { preventNonNumericalInput } from "../../../../utils/utils";
export const validate = (data) => {
  let menuOrderRegex= /^(?:[1-9]|[1-9][0-9])$/

  if (!data.name) return "Please enter menu name";
  if (data.name.length <= 2 || data.name.length > 30) {
    return "Please enter a valid input between 3 and 30 characters";
  }
  if (!spl_format.test(data?.name))
    return "Please enter only alphabets in the name field";
  if (!data?.short_name)
    return "Please provide a short name with exactly 3 characters";
  if (data?.short_name?.length <= 2)
    return "Please provide a short name with exactly 3 characters";
  
    if (data?.shortlist == "")
    return "Please select show on portal";
  if (!data?.menu_order) return "Please provide menu order";
  if (!menuOrderRegex.test(data?.menu_order)) {
    return "Please provide a valid menu order between 1 to 99";
  }
};
export default function Categories() {
  const value = useContext(LicenseConfigureContext);
  const queryKey = "api/license/license-category";
  const [edit, setEdit] = useState(false);
  const [filter, setFilter] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [short, setShort] = useState(null);
  const [shortList, setShortList] = useState(null);
  const [menuOrder, setMenuOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [del, setDel] = useState(false);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [myData, setMyData] = useState([]);
  const [timer, setTimer] = useState(null);
  const [exported, setExported] = useState("");
  const [reason, setReason] = useState(""); //13-Aug-2024 Karthikeyan p1 status log
  const [row_no, setNo] = useState(""); //13-Aug-2024 Karthikeyan p1 status log   
  const postsPerPage = 20;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const {
    data: categoryList,
    isLoading: loading,
    isFetching,
    isSuccess: success,
    refetch,
  } = useQuery([queryKey], LicenseConfigureServices.getCategoryList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : categoryList?.data?.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    if (categoryList?.data) {
      setMyData(categoryList?.data);
    }
  }, [categoryList]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);
  const handleDelete = async (category) => {
    const status = category.status === 1 ? 0 : 1;
    const menu = 'Menus'; //13-Aug-2024 Karthikeyan p1 status log

    try {
      const response = await LicenseConfigureServices.deactivateCategory(
        category?.id,
        {
          status,reason,menu,row_no //13-Aug-2024 Karthikeyan p1 status log
        }
      );
      if (response) {
        const message =
          category?.status === 1
            ? "Menu Deactivated Successfully"
            : "Menu Activated Successfully";
        toast.success(message);
        setDel(null);
        if(name || short || shortList || menuOrder){
          applyFilter(page)
      }else if(searchkey){
        handleSearch({target:{value:searchkey}},page)
      }else{
        refetch();
      }
      }
    } catch (er) {
      toast.error(er);
    }
  };
  const handleEdit = async (category) => {
    try {
      const categoryData = await LicenseConfigureServices.editCategory(
        category?.id
      );
      setId(category?.id);
      setEdit(categoryData?.data);
      value.setShow("categories");
    } catch (er) {
      toast.error(er);
    }
  };

   const applyFilter = async (page) => {
    const payload = { name, short_name: short,shortlist:shortList,menu_order:menuOrder };
    const error = Object.values(payload).filter((dt) => dt !== null);
    if (!error.length) {
      toast.error("Fields Cannot be empty");
      return;
    }

    try {
      const response = await LicenseConfigureServices.filterCategory(payload);
      if (response) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setFilter(false);
        if(!page){
          setPage(1);
        }
        setMyData(response?.data);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(categoryList?.data);
    setName(null);
    setShort(null);
    setShortList(null)
    setMenuOrder(null)
    handleHide();
    setPage(1)
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);

    const payload = { name: value };
    const { data } = await LicenseConfigureServices.searchCategory(payload);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };

  const handleHide = () => {
    setFilter(false);
  };
 
  const handleExport = async () => {
    let response = await LicenseConfigureServices.exportLicenseCategoryType();
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Menu List.csv");
      toast.success("Menus Downloaded Successfully");
      link.click();
    }
    // response?.forEach((element, index) => {
    //   response[index]["S. No"] = index + 1;
    // });
    // setExported(response ? response : []);
  };

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
        {categoryList?.data.length !== myData?.length && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
            onKeyDown={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          <i className="fa fa-filter fa-fw requests-icons"></i>
        </button>
        <button
          className=" button_new pull-right"
          title="Download"
          onClick={handleExport}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </button>
        {/* <CSVLink
          className=" button_new pull-right"
          title="Download"
          filename={"Menus.csv"}
          data={exported}
          onClick={() => {
            if (exported) toast.success("Menus Downloaded Successfully");
          }}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </CSVLink> */}

        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("categories")}
          onKeyDown={() => value.setShow("categories")}
        >
          <i className="fa fa-plus fa-fw requests-icons" title="Add Menu"></i>
        </a>
      </div>

      <div className="table-responsive">
        <table
          className="table table-bordered  table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center text-wrap">Name</th>
              <th className="text-center">Short Name</th>
              <th className="text-center">Show On Portal</th>
              <th className="text-center">Menu Order</th>
              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((category, index) => {
                return (
                  <tr key={category?.id}>
                    <td className="text-start align-middle">
                      {postsPerPage * (page - 1) + index + 1}
                    </td>
                    <td className="text-start align-middle">{category?.name}</td>
                    <td className="text-start align-middle">
                      {category?.short_name}
                    </td>
                    <td className="text-start align-middle">
                      {category?.shortlist == 1 ? "Yes" : "No"}
                    </td>
                    <td className="text-start align-middle">
                      {category?.menu_order}
                    </td>
                    {/* {category.status === 1 ? (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-success"></i>Active
                      </td>
                    ) : (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-fail"></i>Inactive
                      </td>
                    )} */}
                    <td className="text-start align-middle">
                      <a title="Edit" 
                      onClick={() => handleEdit(category)}
                      onKeyDown={() => handleEdit(category)}
                      >
                        <i className="fa fa-edit fa-fw"></i>
                      </a>
                      {category?.status === 1 ? (
                        <i
                          className="fa fa-circle fa-fw txt-success pe-auto"
                          style={{
                            cursor: "pointer",
                          }}
                          title="Deactivate"
                          onClick={() => {setDel(category); setReason("");setNo(postsPerPage * (page - 1) + index + 1)}} //13-Aug-2024 Karthikeyan p1 status log
                          onKeyDown={() => setDel(category)}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-circle fa-fw txt-fail"
                          title="Activate"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {setDel(category); setReason("");setNo(postsPerPage * (page - 1) + index + 1)}} //13-Aug-2024 Karthikeyan p1 status log
                          onKeyDown={() => setDel(category)}
                        ></i>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No data found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to {del?.status === 1 ? "deactivate " : "activate "}
            this menu?
          </p>
            {/* 13-Aug-2024 Karthikeyan p1 status log start */}
            <div>
            <p><label for="w3review">Reason <span style={{ color: "red" }}> *</span></label></p>
            <textarea
                id="w3review"
                placeholder="Reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                style={{ width: "100%" }}
                maxLength={500}
              /> 
            </div>
            {/* 13-Aug-2024 Karthikeyan p1 status log end */}
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
              disabled={!reason} //13-Aug-2024 Karthikeyan p1 status log
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas show={filter} onHide={handleHide} placement="end">
        {/* <ModalDialog style={{ marginTop: "20%" }}> */}
        {/* <div className="modal-content" style={{ marginTop: "20%" }}> */}
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>Filter </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modall_body">
          <div className="container-fluid filter-box pt-3  filterScroll">
            <div className="form-group">
              <label htmlFor="tags"> Menu Name</label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter Menu Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags"> Short Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Short Name"
                maxLength={3}
                value={short}
                onChange={(e) => setShort(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags">Show On Portal</label>
              {/* <input
                type="text"
                className="form-control"
                placeholder=""
                maxLength={3}
                value={short}
                onChange={(e) => setShort(e.target.value)}
              /> */}
               <select
            value={shortList}
            placeholder="Select Show On Portal"
            className="form-control"
            onChange={(e) => setShortList(e.target.value)}
          >
            <option value="">Select Show On Portal</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
            </div>

            <div className="form-group">
              <label htmlFor="tags"> Menu Order</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Menu Order"
                value={menuOrder}
                onkeypress={(e)=>preventNonNumericalInput(e)}
                onKeyDown={(evt) => [".","e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onChange={(e) => {
              
                  // Regular expression to allow only whole numbers
                  if (parseInt(e.target.value) < 0) {
                    setMenuOrder('');
                  }else{
                    setMenuOrder(e.target.value);
                   
                  }
                }}
              />
            </div>
          </div>
          <section className="pull-right m-4" 
          onClick={()=>applyFilter()}
          onKeyDown={()=>applyFilter()}
          >
            <a className="btn button_new_success p-3">Apply</a>
          </section>
        </Offcanvas.Body>
        {/* </div> */}
        {/* </ModalDialog> */}
      </Offcanvas>
      <AddCategories
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        applyFilter={applyFilter}
        filtersAvailable={ name || short || shortList || menuOrder}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}
