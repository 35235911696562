import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { LicenseConfigureContext } from "..";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { validateLicense } from "../licenceTabs/Licenses";
import Editor from "../../../../components/common/Editor/Editor";
export default function AddLicenses({
  refetch,
  editData,
  setEdit,
  clearFilter,
  applyFilter,
  filtersAvailable,
  searchkey,
  handleSearch,
}) {
  const { show, setShow } = useContext(LicenseConfigureContext);
  const [name, setName] = useState(null);
  const [short, setShort] = useState(null);
  const [categories, setCategories] = useState([]);
  const [catId, setCatId] = useState(null);
  const [shortlist, setShortlist] = useState("");
  const [timer, setTimer] = useState(null);
  const [licenseContent, setLicenseContent] = useState(null);
  const [licenseImportantPoints, setLicenseImportantPoints] = useState(null);
  const [licenseFaq, setLicenseFaq] = useState(null);
  const [licenseDocumentContent, setLicenseDocumentContent] = useState(null);

  useEffect(() => {
    const getCategories = async () => {
      const response =
        await LicenseConfigurationServices.getActiveCategoryList();
      if (response?.data?.length) {
        setCategories(response?.data);
      }
    };
    getCategories();
  }, []);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setShort(editData?.short_name);
      setCatId(editData?.license_category_id);
      setShortlist(editData?.shortlist);
      setLicenseContent(editData?.license_content);
      setLicenseImportantPoints(editData?.license_important_points)
      setLicenseFaq(editData?.license_faq)
      setLicenseDocumentContent(editData?.license_document_content)
    }
  }, [editData]);

  const createShortName = async (e) => {
    const { value } = e.target;
    setName(value);
    if (value.length === 0) {
      toast.error("Please enter license");
      clearTimeout(timer);
      setShort("");
      return;
    }

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      
      async function shortNameCreate(){
        try {
          const response = await LicenseConfigurationServices.createShortName({
            short_name: value,
            model_name: "License",
          });
          setShort(response?.data);
        } catch (e) {
          toast.error(e.message);
        }
      }
      shortNameCreate()
    }, 500);
    setTimer(newTimer);
  };

  const addLicense = async () => {
    const payload = {
      name,
      short_name: short,
      license_category_id: catId,
      shortlist,
      license_content: licenseContent,
      license_important_points:licenseImportantPoints,
      license_faq:licenseFaq,
      license_document_content:licenseDocumentContent
    };
    const error = validateLicense(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.addLicense(payload);
      if (response) {
        toast.success("License created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  const updateLicence = async () => {
    const payload = {
      name,
      short_name: short,
      license_category_id: catId,
      shortlist,
      license_content: licenseContent,
      license_important_points:licenseImportantPoints,
      license_faq:licenseFaq,
      license_document_content:licenseDocumentContent
    };
    const error = validateLicense(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.updateLicense(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("License updated successfully");
        // clearFilter();
        handleHide();
        if (filtersAvailable) {
          applyFilter(1);
        } else if (searchkey) {
          handleSearch({ target: { value: searchkey } }, 1);
        } else {
          refetch();
        }
        setImmediate(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };
  const submitForm = () => {
    if (editData) {
      updateLicence();
    } else {
      addLicense();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setShort(null);
    setCatId(null);
    setShortlist(null);
    setEdit(null);
    setLicenseContent(null);
    setLicenseImportantPoints(null)
    setLicenseFaq(null)
    setLicenseDocumentContent(null)
  };

  return (
    <Modal
      show={show === "licenses"}
      animation={false}
      onHide={handleHide}
      dialogClassName="modal-md"
    >
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? "Edit License" : "Add License"}
        </h3>
      </Modal.Header>
      <Modal.Body style={{ height: "auto" }}>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            License Name <span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter License Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Short Name <span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter Short Name"
            value={short}
            maxLength={3}
            onChange={(e) => setShort(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Menu <span style={{ color: "red" }}> *</span>
          </label>

          <select
            className="form-control mb-3"
            id="Shortlist"
            value={catId}
            onChange={(e) => setCatId(e.target.value)}
          >
            <option value={""}>Choose Menu</option>
            {categories?.map((ct) => (
              <option key={ct?.id} value={ct?.id}>
                {ct.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Shortlist <span style={{ color: "red" }}> *</span>
          </label>

          <select
            className="form-control"
            id="Shortlist"
            value={shortlist}
            onChange={(e) => setShortlist(e.target.value)}
          >
            <option value="">Select Value</option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
          <small>
            If marked as YES, the item will display on website home page
          </small>
        </div>
        {/* <div className="form-group">
          <label htmlFor="tags">
            {" "}
            License Content 
          </label>
          <Editor
            data={licenseContent}
            setData={setLicenseContent}
            showSaveBtn={false}
            small={true}
          />
        </div> */}

        {/* <div className="form-group">
          <label htmlFor="tags">
            {" "}
            License Important Points 
          </label>
          <Editor
            data={licenseImportantPoints}
            setData={setLicenseImportantPoints}
            showSaveBtn={false}
            small={true}
          />
        </div> */}

        {/* <div className="form-group">
          <label htmlFor="tags">
            {" "}
            License FAQs 
          </label>
          <Editor
            data={licenseFaq}
            setData={setLicenseFaq}
            showSaveBtn={false}
            small={true}
          />
        </div> */}

        {/* <div className="form-group">
          <label htmlFor="tags">
            {" "}
            License Document Content <span style={{ color: "red" }}> *</span>
          </label>
          <Editor
            data={licenseDocumentContent}
            setData={setLicenseDocumentContent}
            showSaveBtn={false}
            small={true}
          />
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
