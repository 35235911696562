import React, { useEffect, useState } from "react";
import { useRef } from "react";
//------------------Third Party library-----------------------
import DatePicker from "react-datepicker";
import {
  Editor,
  BtnBold,
  BtnItalic,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  EditorProvider,
  Toolbar,
  Separator,
  BtnNumberedList,
  BtnBulletList,
  BtnClearFormatting,
  HtmlButton,
  BtnStyles,
  // subStatusList,
  // setSubStatusList
} from "react-simple-wysiwyg";
import moment from "moment";
//------------------Api handler-----------------------
import AllianceManagerServices from "../../../services/API/AllianceManager";
import { toast } from "react-toastify";
import { format } from "date-fns";
import "../../../styles/alliance-manager/datepicker.css";
import useAuth from "../../../hooks/useAuth";
const UpdateStatus = ({
  licenseStatus,
  setLicenseStatus,
  licenseStatusErrors,
  setCourierDetails,
  setPaymentDetails,
  setCourierDetailsErrors,
  setPaymentDetailsErrors,
  initialCourierDetails,
  initialPaymentDetails,
  updateStatus,
  setFlag,
  flag,
  srnStatues,
  details,
  eligible, //27-08-2024 - p1:status matrix update - Subha
  setSubStatusList,
  subStatusList,
  srnActivity
}) => {
  const auth = useAuth();
  
  const [statusList, setStatusList] = useState([]);
  // const [subStatusList, setSubStatusList] = useState([]);
  const [editorText, setEditorText] = useState();

  const [data, setData] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    if(details){ 
    clearOtherData();
    getFieldsData({executiveId:details?.executive_id,fieldExecutive:details?.field_executive_id});
    if (details?.get_srn_statustes?.renewal_date) {
      setData(details?.get_srn_statustes?.renewal_date);
    }
    if (details?.get_srn_statustes?.is_valid)
      setLicenseStatus({
        ...licenseStatus,
        ["is_valid"]: details?.get_srn_statustes?.is_valid,
      });
    }  
  }, [details]);
  const getFieldsData = async (execFieldExec) => {
    try {
      const statusListRes = await AllianceManagerServices.getStatusList();
      // const subStatusListRes = await AllianceManagerServices.getSubStatusList();
      if (statusListRes.status == "success"){
        let result = statusListRes?.data
        let filtered = []
        if(srnActivity?.length){
          if(srnActivity[0]?.length){
            filtered = srnActivity[0][0]?.filter((item)=>item.status == "Submitted to Authority")
          }
        }
        if(filtered?.length && filtered[0]?.created_at){
          const createdAt = new Date(filtered[0].created_at);
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          if (filtered.length && !(createdAt < today)) {
            result = result?.filter(item => item?.name !== "Closed" && item?.name !== "Completed");
           }
        }else{
          result = result?.filter(item => item?.name !== "Closed" && item?.name !== "Completed");
        }

        if(execFieldExec?.executiveId || execFieldExec?.fieldExecutive){
           if(auth?.roleId != 2 && auth?.roleId != 3){
             result = result?.filter((item)=>item?.name != "Submitted to Authority")
           }
           
        }else{
          result = result?.filter((item)=>item?.name != "Submitted to Authority")
        } 
        setStatusList(result)

        // if(execFieldExec?.executiveId || execFieldExec?.fieldExecutive){
        //   const result = statusListRes?.data?.filter((item)=>item?.name != "Submitted to Authority")
        //   setStatusList(result);
        // }else{
        //   setStatusList(statusListRes?.data);
        // }
      }
      // if (subStatusListRes.status === "success")
      //   setSubStatusList(subStatusListRes?.data);
    } catch (error) {
      toast.error("Something went wrong, try again later.");
    }
  };
  const getSubStatus = async (e) => {
    const value = e.target.value;
    setLicenseStatus({
      ...licenseStatus,
      [e.target.name]: value,
      sub_status: "",
    });
    try {
      const resp = await AllianceManagerServices.getSubStatusList({
        license_name: value,
      });
      console.log(resp);
      if (resp) {
        setSubStatusList(resp?.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  const clearOtherData = () => {
    setCourierDetails(initialCourierDetails);
    setPaymentDetails(initialPaymentDetails);
    setCourierDetailsErrors({});
    setPaymentDetailsErrors({});
  };

  return (
    <div className="card card-sm card-shadow">
      <div className="card-body">
        <h3 className="mb-3">Update Status</h3>
        <div className="mb-3">
          {(srnStatues?.status === "Closed" ||
            srnStatues?.status === "Completed") && (
            <p className="mb-3 text-warning">
              Note: Request closed/completed already, no further updates
              possible.
            </p>
          )}
          {(eligible === false) && ( //27-08-2024 - p1:status matrix update - Subha
            <p className="mb-3 text-warning">
              Note: You are not eligible to close/complete the request.
            </p>
          )}
          <label className="label">
            Status<span style={{ color: "red" }}> *</span>
          </label>
          <label className="select">
            <select
              disabled={
                srnStatues?.status === "Closed" ||
                srnStatues?.status === "Completed" || eligible === false //27-08-2024 - p1:status matrix update - Subha
              }
              onChange={getSubStatus}
              value={licenseStatus.status}
              className="input-sm"
              name="status"
            >
              <option value="">Select Status</option>
              {statusList &&
                statusList.map((item, key) => (
                  <option value={item?.name} key={key}>
                    {item.name}
                  </option>
                ))}
            </select>{" "}
            <p
              style={{
                color: "red",
                fontSize: "12px",
              }}
            >
              {licenseStatusErrors.status}
            </p>
          </label>
        </div>
        <div className="mb-3">
          <label className="label">
            Sub Status
            {subStatusList?.length ? (
              <span style={{ color: "red" }}> *</span>
            ) : (
              ""
            )}
          </label>
          <label className="select">
            <select
              disabled={
                srnStatues?.status === "Closed" ||
                srnStatues?.status === "Completed" || eligible === false //27-08-2024 - p1:status matrix update - Subha
              }
              onChange={(e) => {
                setLicenseStatus({
                  ...licenseStatus,
                  [e.target.name]: e.target.value,
                });
              }}
              value={licenseStatus?.sub_status}
              className="input-sm"
              name="sub_status"
            >
              <option value="">Select Sub-Status</option>
              {subStatusList &&
                subStatusList.map((item, key) => (
                  <option value={item.name} key={key}>
                    {item.name}
                  </option>
                ))}
            </select>{" "}
            {/* <i></i>{" "} */}
            <p
              style={{
                color: "red",
                fontSize: "12px",
              }}
            >
              {licenseStatusErrors.sub_status}
            </p>
          </label>
        </div>
        <div className="mb-0">
          <label className="label">
            Select Renewal Date
            {(licenseStatus.status == "Closed" ||
            licenseStatus?.status === "Completed" || eligible === false) && details?.get_mapped_licenses?.validity != "lifetime"  ? ( //27-08-2024 - p1:status matrix update - Subha
              <span style={{ color: "red" }}> *</span>
            ) : (
              ""
            )}
          </label>
          <div className="input-sm custCal">
            <DatePicker
              minDate={moment().toDate()}
              selected={licenseStatus?.renewal_date}
              onChange={(date) => {
                setLicenseStatus({
                  ...licenseStatus,
                  ["renewal_date"]: date,
                  ["is_valid"]: 0,
                });
                setData(date);
              }}
              type="date"
              ref={ref}
              value={data}
              timeInputLabel="Time:"
              dateFormat="dd-MM-yy hh:mm a"
              showTimeInput
              className="py-1 px-1"
              placeholderText="Select date"
              disabled={
                srnStatues?.status === "Closed" ||
                srnStatues?.status === "Completed" || eligible === false || //27-08-2024 - p1:status matrix update - Subha
                details?.get_srn_statustes?.renewal_date ||
                licenseStatus.is_valid == 1 || 
                details?.get_mapped_licenses?.validity == "lifetime"
              }
            />
          </div>
          <p
            style={{
              color: "grey",
              fontSize: "12px",
            }}
          >
            {details?.get_srn_statustes?.renewal_date
              ? "Renewal date already updated"
              : ""}
          </p>
          <p
            style={{
              color: "red",
              fontSize: "12px",
            }}
          >
            {licenseStatusErrors.renewal_date}
          </p>
        </div>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckChecked"
            name="is_valid"
            disabled={data || details?.get_srn_statustes?.is_valid == 1 || details?.get_mapped_licenses?.validity == "lifetime" || eligible === false} //27-08-2024 - p1:status matrix update - Subha
            checked={licenseStatus.is_valid == 1 || details?.get_mapped_licenses?.validity == "lifetime" ? true : false}
            onChange={(e) => {
              if (flag == false) {
                setFlag(true);
                setData("");
              } else {
                setFlag(false);
              }

              let num = licenseStatus[e.target.name] ? 0 : 1;
              setLicenseStatus({
                ...licenseStatus,
                [e.target.name]: num,
              });
            }}
          />
          <label className="form-check-label mx-2" for="flexCheckChecked">
            Is Valid for Lifetime?
          </label>
        </div>

        <div className="mb-3">
          <EditorProvider>
            <Editor
              disabled={
                srnStatues?.status === "Closed" ||
                srnStatues?.status === "Completed" || eligible === false //27-08-2024 - p1:status matrix update - Subha
              }
              value={licenseStatus.comment}
              onChange={(e) => {
                setLicenseStatus({
                  ...licenseStatus,
                  comment: e.target.value,
                });
              }}
              style={{
                overflowY: "scroll",
                height: "80px",
                padding: "10px 15px",
              }}
            >
              <Toolbar>
                <BtnUndo />
                <BtnRedo />
                <Separator />
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnStrikeThrough />
                <Separator />
                <BtnNumberedList />
                <BtnBulletList />
                <BtnClearFormatting />
                <HtmlButton />
                <Separator />
                <BtnStyles />
                <Separator />
              </Toolbar>
            </Editor>
          </EditorProvider>

          <p
            style={{
              color: "red",
              fontSize: "12px",
            }}
          >
            {licenseStatusErrors.comment}
          </p>
        </div>
        <div>
          <label className="label">
            Attachment
            {licenseStatus.status == "Closed" ||
            licenseStatus?.status === "Completed" ? (
              <span style={{ color: "red" }}> *</span>
            ) : (
              ""
            )}
          </label>
          <div className="input input-file">
            <span className="button button_new">
              <input
                type="file"
                disabled={
                  srnStatues?.status === "Closed" ||
                  srnStatues?.status === "Completed" || eligible === false //27-08-2024 - p1:status matrix update - Subha
                }
                onChange={(e) => {
                  if (e.target.files) {
                    let arr = e.target.files[0]?.name?.split(".");
                    setLicenseStatus({
                      ...licenseStatus,
                      ["document"]: e.target.files[0],
                      documentType: arr[arr?.length - 1],
                    });
                  }
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
                value={licenseStatus?.document[0]}
                // onChange={e  => getFile(e.target.files[0])}
              />
              Browse
            </span>
            <input
              type="text"
              placeholder="Attach file as required"
              value={
                licenseStatus.document &&
                `${
                  licenseStatus?.document?.name?.length > 50
                    ? licenseStatus.document.name?.slice(0, 50) + "..."
                    : licenseStatus.document.name
                }`
              }
              disabled
            />
            <p
              style={{
                color: "red",
                fontSize: "12px",
              }}
            >
              {licenseStatusErrors.document}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-end pt-10">
          <button
            style={{ background: "green", color: "white" }}
            className="btn button_new"
            onClick={() => {
              updateStatus();
            }}
            disabled={
              srnStatues?.status === "Closed" ||
              srnStatues?.status === "Completed" || eligible === false //27-08-2024 - p1:status matrix update - Subha
            }
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateStatus;
